import React, { useEffect, useRef, useState, Fragment } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";

import "./agriculture-modal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faAdd,
  faCircleXmark,
  faCloudUploadAlt,
  faFilePdf,
  faMinus,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  IconButton,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  FormControl,
  TextField,
  FormGroup,
  Switch,
} from "@mui/material";

import ButtonUI from "../../../../components/ui/button/button";
import FieldsetUI from "../../../../components/ui/fieldset/fieldset";
import PreviewImageUI from "../../../../components/ui/preview-image/preview-image";
import SwalUI from "../../../../components/ui/swal-ui/swal-ui";
import { svUpdateProduct } from "../../../../services/productServices/pageagriculture.service";
import CKeditorComponent from "../../../../components/ui/ckeditor/ckeditor";

function AgricultureEditMpdal(props) {
  const { t, isEdit, isOpen, category, items } = props;

  const thumbnailDefault = {
    thumbnail: true,
    src: "",
    file: null,
    name: null,
    remove: false,
  };

  const [isError, setIsError] = useState({
    thumbnail: false,
    slug: false,
    // keyword:false,
    title: false,
    category: false,
    description: false,
    seement: false,
    hr: false,
    ir: false,
  });

  const uploadPath = useSelector((state) => state.app.uploadPath);
  const language = useSelector((state) => state.app.language);

  const [previews, setPreviews] = useState(thumbnailDefault);
  const [editData, setEditData] = useState(items);
  const [isFetching, setIsFetching] = useState(false);
  const [ckValue, setCkValue ] = useState(null)
  const scrollRef = useRef(null);

  useEffect(() => {
    /* Set thumbnail */
    let thumbnail = uploadPath + items.thumbnail_link;
    setPreviews({
      file: null,
      src: thumbnail,
      remove: true,
      srcDefault: thumbnail,
    });

    setCkValue(items.description)

  }, [items]);

  const setPreviewHandler = (data) => {
    setEditData({ ...editData, imageName: data.file ? data.file.name : "" });
    setPreviews(data);
  };

  const priorityHandler = (isAdding) => {
    if (isAdding) {
      setEditData((prevState) => {
        return { ...prevState, priority: parseInt(editData.priority) + 1 };
      });
    } else if (parseInt(editData.priority) > 1) {
      setEditData((prevState) => {
        return { ...prevState, priority: parseInt(editData.priority) - 1 };
      });
    }
  };

  const editValidators = () => {
    let isValid = true;

    let error = {
      ...isError,
      title: editData.title.trim().length < 1,
    };

    setIsError(() => error);
    const hasError = Object.values(error).find((err) => err === true);
    if (hasError) isValid = false;

    if (isValid) {
      const formData = new FormData();
      if (previews.file) {
        formData.append("Image", previews.file);
        formData.append("ImageName", editData.imageName);
      }
      formData.append("thumbnail_link", editData.thumbnail_link);
      formData.append("thumbnail_title", editData.thumbnail_title);
      formData.append("thumbnail_alt", editData.thumbnail_alt);
      formData.append("id", editData.id);
      // formData.append("keyword", editData.keyword);
      formData.append("slug", editData.slug);
      formData.append("title", editData.title);
      formData.append("category", editData.category);
      formData.append("description", ckValue);
      formData.append("seement", editData.seement);
      formData.append("hr", editData.hr);
      formData.append("ir", editData.ir);
      // formData.append("link_facebook", editData.link_facebook);
      // formData.append("link_twitter", editData.link_twitter);

      formData.append("display", editData.display ? 1 : 0);
      formData.append("pin", editData.pin ? 1 : 0);
      formData.append("priority", editData.priority);
      formData.append("language", isEdit ? editData.language : language);
      saveHandler(formData);
    } else {
      scrollToTop();
    }
  };

  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const saveHandler = (formData) => {
    setIsFetching(true);
    /* Display the key/value pairs*/
    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }
    setIsFetching(false);

    svUpdateProduct(editData.id, formData).then((res) => {
      setIsFetching(false);
      if (res.status === 200) {
        props.setClose({
          isEdit: false,
          isOpen: false
        })
        SwalUI({ status: res.data.status, description: res.data.description });
        props.setRefreshData((prev) => prev + 1);
      } else {
        SwalUI({ status: res.data.status, description: res.data.description });
      }
    });
  };
//  console.log(props);
  return (
    <Fragment>
      <Modal
        disableEnforceFocus
        open={isOpen}
        onClose={() =>
          props.setClose({
            isEdit: false,
            isOpen: false,
          })
        }
        className={"modal-edit-agriculture"}
        aria-labelledby="modal-edit-agriculture"
        aria-describedby="modal-edit-agriculture"
      >
        <Box className="modal-custom">
          <div className="modal-header">
            <h2>
              <FontAwesomeIcon icon={faEdit} />
              <span>{t("ModalEditProduct")}</span>
            </h2>
            <IconButton
              className="param-generator"
              color="error"
              sx={{ p: "10px" }}
              onClick={() =>
                props.setClose({
                  isEdit: false,
                  isOpen: false,
                })
              }
            >
              <FontAwesomeIcon icon={faXmark} />
            </IconButton>
          </div>

          <div className="modal-body overflow-scroll-custom">
            <fieldset className="modal-fieldset">
             
              <div
                className={`box-checkbox-container cate-menu-list
               
              ${isError.category ? "error" : ""}`}
              >
                <div className="brc-body overflow-scroll-custom">
                  {/* RadioBoxUI */}
                  <FormControl className="brc-form-control">
                    <FormLabel id="box-checkbox">{t("Category")}</FormLabel>
                    <RadioGroup
                      aria-labelledby="box-checkbox"
                      name="controlled-checkbox-buttons-group"
                    >
                      {category &&
                        category.map((e) => {
                          return (
                            <FormControlLabel
                              key={e.id}
                              onChange={(e) =>
                                setEditData((prevState) => {
                                  return {
                                    ...prevState,
                                    category: e.target.value,
                                  };
                                })
                              }
                              checked={
                                parseInt(e.id) === parseInt(editData.category)
                                  ? true
                                  : false
                              }
                              value={e.id}
                              control={<Radio />}
                              label={e.title}
                            />
                          );
                        })}
                    </RadioGroup>
                  </FormControl>
                  
                </div>
              </div>
              <div className="form-details">
                {/* รูป */}
                <FieldsetUI
                  className={`image-setting ${
                    isError.thumbnail ? "error" : ""
                  }`}
                  label={t("Image Info")}
                >
                  <PreviewImageUI
                    className="edit-image"
                    previews={previews}
                    setPreviews={setPreviewHandler}
                    setData={setEditData}
                  />
                  <div className="image-detail">
                    <TextField
                      onChange={(e) =>
                        setEditData((prevState) => {
                          return { ...prevState, imageName: e.target.value };
                        })
                      }
                      value={editData.thumbnail_link}
                      className={`text-field-custom ${
                        !previews.file ? "inp-hidden" : ""
                      }`}
                      fullWidth={true}
                      error={false}
                      id="image-name"
                      label={t("ModalImageName")}
                      size="small"
                    />
                    <TextField
                      onChange={(e) =>
                        setEditData((prevState) => {
                          return {
                            ...prevState,
                            thumbnail_title: e.target.value,
                          };
                        })
                      }
                      value={editData.thumbnail_title}
                      className="text-field-custom"
                      fullWidth={true}
                      error={false}
                      id="image-title"
                      label={t("ModalImageTitle")}
                      size="small"
                    />
                    <TextField
                      onChange={(e) =>
                        setEditData((prevState) => {
                          return {
                            ...prevState,
                            thumbnail_alt: e.target.value,
                          };
                        })
                      }
                      value={editData.thumbnail_alt}
                      className="text-field-custom"
                      fullWidth={true}
                      error={false}
                      id="image-tag"
                      label={t("ModalAltDescription")}
                      size="small"
                    />
                  </div>
                </FieldsetUI>
                {/* รายละเอียด */}
                <div className="category-details">
                  <h3 className="category-detail-title">{t("ModalDetail")}</h3>
                  {/* <div className="input-half">
                  <TextField
                    onChange={(e) =>
                      setEditData((prevState) => {
                        return { ...prevState, keyword: e.target.value };
                      })
                    }
                    value={editData.keyword}
                    className="text-field-custom"
                    fullWidth={true}
                    error={isError.keyword}
                    id="ed-keyword"
                    label={t("ModalCategoriesName")}
                    size="small"
                  />
                </div> */}
                <div className="input-full">
                  <TextField
                    onChange={(e) =>
                      setEditData((prevState) => {
                        return { ...prevState, slug: e.target.value };
                      })
                    }
                    value={editData.slug}
                    className="text-field-custom"
                    fullWidth={true}
                    error={isError.slug}
                    id="ed-slug"
                    label={t("ModalCategorieskeyword")}
                    size="small"
                  />
                </div>
                  <div className="input-full">
                    <TextField
                      onChange={(e) =>
                        setEditData((prevState) => {
                          return { ...prevState, title: e.target.value };
                        })
                      }
                      value={editData.title}
                      className="text-field-custom"
                      fullWidth={true}
                      error={isError.title}
                      id="ed-title"
                      label={t("ModalCategoriesTitle")}
                      size="small"
                    />
                  </div>
                  <div className="ck-content">
                  <label className="ck-edit-agriculture">{t('ModalAgricultureDescription')}</label>
                  {ckValue !== null && (
                    <CKeditorComponent
                      ckNameId="ck-edit-agriculture"
                      value={ckValue} 
                      onUpdate={setCkValue} 
                    />
                  )}
              
                </div>
                  <div className="input-half">
                    <TextField
                      onChange={(e) =>
                        setEditData((prevState) => {
                          return { ...prevState, seement: e.target.value };
                        })
                      }
                      value={editData.seement}
                      className="text-field-custom"
                      fullWidth={true}
                      error={isError.seement}
                      id="ad-seement"
                      label={t("seement")}
                      size="small"
                    />
                  </div>
                  <div className="input-half">
                    <TextField
                      onChange={(e) =>
                        setEditData((prevState) => {
                          return { ...prevState, hr: e.target.value };
                        })
                      }
                      value={editData.hr}
                      className="text-field-custom"
                      fullWidth={true}
                      error={isError.hr}
                      id="ad-hr"
                      label={t("hr")}
                      size="small"
                    />
                  </div>
                  <div className="input-half">
                    <TextField
                      onChange={(e) =>
                        setEditData((prevState) => {
                          return { ...prevState, ir: e.target.value };
                        })
                      }
                      value={editData.ir}
                      className="text-field-custom"
                      fullWidth={true}
                      error={isError.ir}
                      id="ad-ir"
                      label={t("ir")}
                      size="small"
                    />
                  </div>
                  {/*<div className="input-full">
                    <TextField
                      onChange={(e) =>
                        setEditData((prevState) => {
                          return { ...prevState, link_facebook: e.target.value };
                        })
                      }
                      value={editData.link_facebook}
                      className="text-field-custom"
                      fullWidth={true}
                      error={false}
                      id="ed-linkfacebook"
                      label={t("Link Facebook")}
                      size="small"
                    />
                  </div>
                  <div className="input-full">
                    <TextField
                      onChange={(e) =>
                        setEditData((prevState) => {
                          return { ...prevState, link_twitter: e.target.value };
                        })
                      }
                      value={editData.link_twitter}
                      className="text-field-custom"
                      fullWidth={true}
                      error={false}
                      id="ed-Link Twitter"
                      label={t("Link Twitter")}
                      size="small"
                    />
                  </div>*/}
                  {/* Settings */}
                  <div className="group-settings">
                    <h3 className="post-detail-titile">{t("Settings")}</h3>
                    <div className="setting-controls">
                      <div className="switch-form">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                onChange={(e) =>
                                  setEditData({
                                    ...editData,
                                    display: e.target.checked,
                                  })
                                }
                                checked={editData.display ? true : false}
                              />
                            }
                            label={t("display")} //การแสดงผล
                            labelPlacement="start"
                          />
                        </FormGroup>
                      </div>
                      {/* <div className="switch-form">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                onChange={(e) =>
                                  setEditData({
                                    ...editData,
                                    pin: e.target.checked,
                                  })
                                }
                                checked={editData.pin ? true : false}
                              />
                            }
                            label={t("Pin")}
                            labelPlacement="start"
                          />
                        </FormGroup>
                      </div> */}
                      <div className="input-group-settings">
                        <div className="inp">
                          <ButtonUI
                            color="error"
                            onClick={(e) => priorityHandler(false)}
                          >
                            <FontAwesomeIcon icon={faMinus} />
                          </ButtonUI>
                          <span className="title">
                            {t("Priority")} {editData.priority}
                          </span>
                          <ButtonUI onClick={(e) => priorityHandler(true)}>
                            <FontAwesomeIcon icon={faAdd} />
                          </ButtonUI>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div className="modal-footer">
            {/* ปุ่ม */}
            <ButtonUI
              loader={true}
              isLoading={isFetching}
              className="btn-save"
              on="save"
              width="md"
              onClick={editValidators}
            >
              {t("Save")}
            </ButtonUI>
            <ButtonUI
              className="btn-cancel"
              on="cancel"
              width="md"
              onClick={() =>  props.setClose({
                isEdit: false,
                isOpen: false,
              })}
            >
              {t("Cancel")}
            </ButtonUI>
          </div>
        </Box>
      </Modal>
    </Fragment>
  );
}

export default AgricultureEditMpdal;
