import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './preview-image.scss';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

/*  Example 
    const [previews, setPreviews] = useState({src:"" ,file: null })
    <PreviewImageUI previews={previews} setPreviews={setPreviews} />
*/

const PreviewImageUI = (props) => {
  let { previews, setPreviews, setData } = props;
  const defaultURL = useSelector((state) => state.app.defaultURL);
  const srcError = defaultURL + 'images/no-image.png';
  const [isError, setIsError] = useState(false);
  let { image, value } = '';

  const convertImagePreview = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const previewImageHandler = async (e) => {
    value = e.target.files[0];
    image = await convertImagePreview(value);
    if (value && value.size < 2000000) {
      setPreviews({
        index: previews.index,
        src: image,
        file: value,
        remove: true,
      });
      e.target.value = null;
      setIsError(false);
    } else {
      setIsError(true);
      setPreviews({
        ...previews,
        file: null,
        src: null,
      });
    }

    setPreviews({
      index: previews.index,
      src: image,
      file: value,
      remove: true,
    });

    if (setData) {
      setData((prev) => {
        return {
          ...prev,
          thumbnail_title: '',
          thumbnail_alt: '',
          cate_thumbnail_title: '',
          cate_thumbnail_alt: '',
        };
      });
    }

    e.target.value = null;
  };
  const imageError = (e) => {
    if (previews.file !== '' || previews.src !== '') {
      setPreviews({
        ...previews,
        remove: false,
      });
    }

    return e.target.setAttribute('src', srcError);
  };

  const removeImageHandler = (e) => {
    let _removeId = previews.removeId ? previews.removeId : null;

    setPreviews({
      index: previews.index,
      removeId: _removeId,
      file: undefined,
      src: undefined,
    });

    if (setData) {
      setData((prev) => {
        return {
          ...prev,
          thumbnail_name: '',
          thumbnail_link: '',
          thumbnail_title: '',
          thumbnail_alt: '',
          cate_thumbnail: '',
          cate_thumbnail_title: '',
          cate_thumbnail_alt: '',
        };
      });
    }
  };

  return (
    <>
      <div
        className={`upload-image-preview ${props.className}`}
        style={props.style}
      >
        <div className="group">
          <button
            type="button"
            className={`remove-image-btn ${previews.remove ? 'show' : 'hide'}`}
            onClick={removeImageHandler}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <figure className="image-upload" style={props.inputFileStyle}>
            <input
              className="inp-file"
              type="file"
              onChange={(e) => previewImageHandler(e)}
            />
            {isError ? (
              <div className="error-message">
                <strong>
                  รูปมีขนาดเกิน <br /> 2 MB
                </strong>
                <AddPhotoAlternateIcon />
              </div>
            ) : (
              <img
                title={previews.adImageTitle}
                className="image-preview"
                src={previews.src || srcError}
                onError={imageError}
              />
            )}
          </figure>
        </div>
      </div>
    </>
  );
};

export default PreviewImageUI;
